"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUrlSafeBase64 = exports.urlSafeBase64ToBase64 = exports.urlEncodeBase64 = void 0;
var urlEncodeBase64 = function (signature) {
    signature = signature.replace(/(=+)$/g, '');
    signature = signature.replace(/\//g, '_');
    signature = signature.replace(/\+/g, '-');
    return signature;
};
exports.urlEncodeBase64 = urlEncodeBase64;
var urlSafeBase64ToBase64 = function (signature) {
    if ((0, exports.isUrlSafeBase64)(signature)) {
        return signature.replace(/-/g, '+').replace(/_/g, '/');
    }
    return signature;
};
exports.urlSafeBase64ToBase64 = urlSafeBase64ToBase64;
var isUrlSafeBase64 = function (content) {
    return /^[A-Za-z0-9_-]*[.=]{0,2}$/.test(content);
};
exports.isUrlSafeBase64 = isUrlSafeBase64;
